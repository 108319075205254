// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "../stylesheets/application.css"
import './nested_forms/add_fields';
import './nested_forms/remove_fields';
import './application/sentry';
import htmxLib from 'htmx.org';

Rails.start()
Turbolinks.start()
ActiveStorage.start()
document.addEventListener('turbolinks:load', function() {
    htmxLib.process(document.body);
});